import * as _ from "lodash";
import { Row } from "./Row";
import { Tile } from "./Tile";

export const GuessRow: React.FC<{
  currentGuessIndex: number;
  answer: string[][];
  currentAnswer: string[];
  onCorrect: () => void;
  currentGuessRow: string[];
  currentlyCheckingAnswer: string[];
  currentGuess: string;
}> = ({
  currentGuess,
  currentlyCheckingAnswer,
  currentGuessRow,
  onCorrect,
  currentGuessIndex,
  currentAnswer,
  answer,
}) => {
  if (currentGuessIndex === answer.length) return null;

  return (
    <Row
      // terrible hack
      key={currentAnswer.join("")}
    >
      {currentGuessRow.map((letter, letterIndex, guessLetters) => {
        const isLast = letterIndex === currentAnswer.length - 1; // TODO 5

        const lettersLeft: Record<string, number> = {};
        _.each(answer[currentGuessIndex], (ansLetter) => {
          lettersLeft[ansLetter] = (lettersLeft[ansLetter] || 0) + 1;
        });

        // green accounting
        guessLetters.forEach((guessLetter, index) => {
          if (answer[currentGuessIndex][index] === guessLetter) {
            if (lettersLeft[guessLetter]) {
              lettersLeft[guessLetter] -= 1;
            }
          }
        });

        // yellow accounting
        guessLetters
          .slice(0, letterIndex)
          .forEach((guessLetterUptoThisPoint, index) => {
            // remove letters use in the guess, but dont double count the correct ones you've already deducted
            if (
              lettersLeft[guessLetterUptoThisPoint] &&
              answer[currentGuessIndex][index] !== guessLetterUptoThisPoint
            ) {
              lettersLeft[guessLetterUptoThisPoint] -= 1;
            }
          });

        return (
          <Tile
            lettersLeft={lettersLeft}
            isGuess
            onCorrect={isLast ? onCorrect : undefined}
            guessAnswerWord={
              currentlyCheckingAnswer[letterIndex] &&
              currentGuess.length === currentAnswer.length // TODO 5
                ? answer[currentGuessIndex]
                : undefined
            }
            guessAnswerLetter={
              currentGuess.length === currentAnswer.length // TODO 5
                ? currentlyCheckingAnswer[letterIndex]
                : undefined
            }
            // eslint-disable-next-line react/no-array-index-key
            key={letterIndex}
            letter={letter}
          />
        );
      })}
    </Row>
  );
};
