export const Row: React.FC<{ style?: React.CSSProperties }> = ({
  children,
  style,
}) => {
  return (
    <div
      style={{
        display: "flex",
        columnGap: 4,
        justifyContent: "center",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
