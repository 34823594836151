import { useEffect, useRef, useState } from "react";
import { globalConstants } from "./globalConstants";
import { ConfettiCannon } from "./ReactConfetti";
import { Tile } from "./Tile";
import { Row } from "./Row";
import { GuessRow } from "./GuessRow";

const answer = globalConstants.puzzleAnswer;

export const NUMBER_OF_GUESSES = answer.length;
export type Guess = [string, string, string, string, string];

export const Board: React.FC<{
  setDone: (boolea: boolean) => void;
  onCorrectGuess: () => void;
  checkCurrentGuess: boolean;
  currentGuess: string;
  pastGuesses: Guess[];
  setCheckCurrentGuess: (flag: boolean) => void;
}> = ({
  setDone,
  onCorrectGuess,
  checkCurrentGuess,
  currentGuess,
  pastGuesses,
  setCheckCurrentGuess,
}) => {
  // unintutive but zero index
  const currentGuessIndex = pastGuesses.length;
  // minus the current guess row
  const numberOfRemaingGuesses = NUMBER_OF_GUESSES - pastGuesses.length - 1;

  const currentAnswer = answer[currentGuessIndex];
  const currentGuessRow = [
    // currentGuess[0],
    // currentGuess[1],
    // currentGuess[2],
    // currentGuess[3],
    // currentGuess[4],
    ...(currentAnswer || []).map((letter, index) => {
      if (currentGuess[index]) return currentGuess[index];
      return "";
    }),
  ];

  const [currentlyCheckingAnswer, setCurrentlyCheckingAnswer] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (!checkCurrentGuess) return;
    const answerWord = answer[currentGuessIndex];
    setCheckCurrentGuess(false);

    // TODO
    for (let i = 0; i < answerWord.length; i++) {
      setTimeout(() => {
        setCurrentlyCheckingAnswer(answerWord.slice(0, i + 1));
        // TODO
        if (i === answerWord.length - 1) {
          if (currentGuess === answerWord.join("")) {
            setTimeout(() => {
              // eslint-disable-next-line no-console
              console.log("correct!");
              if (currentGuessIndex === answer.length - 1) return setDone(true);
              // onCorrectGuess()
              // setCurrentlyCheckingAnswer([])
            }, 2000);
          } else {
            // try again
            setTimeout(() => {
              // eslint-disable-next-line no-console
              console.log("clearing");
              setCurrentlyCheckingAnswer([]);
            }, 2000);
          }
        }
      }, i * 500);
    }
  }, [
    setDone,
    checkCurrentGuess,
    currentGuessIndex,
    setCheckCurrentGuess,
    setCurrentlyCheckingAnswer,
    onCorrectGuess,
    currentGuess,
  ]);

  const rowIterator =
    numberOfRemaingGuesses > 0
      ? new Array(numberOfRemaingGuesses).fill("placeholder")
      : [];

  const canonRef = useRef({ fire: () => {} });

  const onCorrect = () => {
    const answerWord = answer[currentGuessIndex];
    if (currentGuess === answerWord.join("")) {
      onCorrectGuess();
      setCurrentlyCheckingAnswer([]);
      canonRef.current.fire();
    }
  };

  const getGuessRow = () => {
    return (
      <GuessRow
        currentGuessIndex={currentGuessIndex}
        answer={answer}
        currentAnswer={currentAnswer}
        onCorrect={onCorrect}
        currentGuessRow={currentGuessRow}
        currentlyCheckingAnswer={currentlyCheckingAnswer}
        currentGuess={currentGuess}
      />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
        backgroundColor: "white",
      }}
    >
      <ConfettiCannon ref={canonRef} />
      {pastGuesses.map((pastGuess, guessIndex) => {
        return (
          <Row key={pastGuess.join("")}>
            {Array.from(pastGuess).map((letter, letterIndex) => {
              return (
                <Tile
                  guessAnswerWord={answer[guessIndex]}
                  guessAnswerLetter={answer[guessIndex][letterIndex]}
                  letter={letter}
                  // eslint-disable-next-line react/no-array-index-key
                  key={letter + letterIndex}
                />
              );
            })}
          </Row>
        );
      })}
      {getGuessRow()}
      {rowIterator.map((_, rowIndex) => {
        const rowAnswer = answer[pastGuesses.length + 1 + rowIndex];
        const guessLength = rowAnswer.length;
        const letterIterator = new Array(guessLength).fill("placeholder");
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={`${rowAnswer[0]}-${rowIndex}`}>
            {letterIterator.map((_unused, index) => {
              // eslint-disable-next-line react/no-array-index-key
              return <Tile key={index} />;
            })}
          </Row>
        );
      })}
    </div>
  );
};
