import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CharacterReveal } from "react-text-reveal";
import { StyleSheet, css } from "aphrodite";
import "./App.css";
// import CryptoJS from "crypto-js";
import { WelcomeModal } from "./WelcomeModal";
import { Board, Guess } from "./Board";
import { Keyboard } from "./Keyboard";
import { FloatingFace } from "./FloatingFace";
import { globalConstants } from "./globalConstants";

const styles = StyleSheet.create({
  button: {
    color: "lightgray",
    ":hover": {
      color: "red",
    },
  },
});

// have them all start use transition

function App() {
  const isMobile = window.innerWidth < 500;

  // const hash = window.location.hash.slice(1);

  const content = (() => {
    const { letterMobile, letter } = (() => {
      // make this optional in the future
      return {
        letter: globalConstants.letter.letter,
        letterMobile: globalConstants.letter.letterMobile,
      };
      // try {
      //   const letterBytes = CryptoJS.AES.decrypt(
      //     globalConstants.letter.letterEncrypted,
      //     hash
      //   );
      //   const letterDecrypted = letterBytes.toString(CryptoJS.enc.Utf8);
      //   const letterMobileBytes = CryptoJS.AES.decrypt(
      //     globalConstants.letter.letterMobileEncrypted,
      //     hash
      //   );
      //   const letterMobileDecrypted = letterMobileBytes.toString(
      //     CryptoJS.enc.Utf8
      //   );

      //   return {
      //     isPrivate: true,
      //     letter: JSON.parse(letterDecrypted) as string[],
      //     letterMobile: JSON.parse(letterMobileDecrypted) as string[],
      //   };
      // } catch (error) {
      //   // eslint-disable-next-line no-console
      //   return {
      //     isPrivate: false,
      //     letter: globalConstants.letter.letterBlocked,
      //     letterMobile: globalConstants.letter.letterMobileBlocked,
      //   };
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    })();

    const actualLetter = isMobile ? letterMobile : letter;
    return {
      letter: actualLetter,
      isPrivate: true,
    };
  })();

  // console.log("content.letter", JSON.stringify(content.letter, null, 2));

  const [isDone, setDone] = useState(false);
  const [isMove, setMove] = useState(false);
  const [isMoveDone, setMoveDone] = useState(false);
  const [isReveal, setReveal] = useState(false);
  const [isRevealText, setRevealText] = useState(false);

  useEffect(() => {
    if (!isDone) return;
    setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log("starting other animation");
      setMove(true);
    }, 3000);
  }, [isDone]);

  useEffect(() => {
    if (!isReveal) return;
    setTimeout(() => {
      setRevealText(true);
    });
  }, [isReveal]);

  useEffect(() => {
    if (!isMove) return;
    setTimeout(() => {
      setMoveDone(true);
    }, 4000);
  }, [isMove]);

  // useEffect(() => {
  //   setDone(true)
  // }, [])
  const [pastGuesses, setGuesses] = useState<Guess[]>([]);
  const [currentGuess, setCurrentGuess] = useState("");

  const [checkCurrentGuess, setCheckCurrentGuess] = useState(false);

  const currentRowAnswer = useMemo(() => {
    return globalConstants.puzzleAnswer[pastGuesses.length];
  }, [pastGuesses]);

  const onCorrectGuess = useCallback(() => {
    setGuesses((guesses) => [...guesses, Array.from(currentGuess) as Guess]);
    setCurrentGuess("");
  }, [currentGuess]);

  const enter = useCallback(() => {
    // TODO 5
    if (currentGuess.length === currentRowAnswer.length) {
      setCheckCurrentGuess(true);
    }
  }, [currentGuess, currentRowAnswer]);

  const backspace = useCallback(() => {
    setCurrentGuess((currentValue) => {
      if (currentValue.length) {
        return currentValue.slice(0, currentValue.length - 1);
      }
      return currentValue;
    });
  }, []);
  // TODO 5
  const onChange = useCallback(
    (newLetter: string) => {
      setCurrentGuess((currentValue) => {
        if (currentValue.length === currentRowAnswer.length)
          return currentValue;
        return currentValue + newLetter;
      });
    },
    [currentRowAnswer]
  );

  const faceIterator = new Array(isMobile ? 50 : 70).fill("placeholder");

  const [isHovered, setHovered] = useState(false);

  const letterOverflowStyles: React.CSSProperties =
    isReveal && content.isPrivate
      ? {
          border: "1px solid #6aaa64",
          borderRadius: "4px",
          padding: "8px 16px",
          overflow: "auto",
        }
      : {};

  useEffect(() => {
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", documentHeight);
    documentHeight();
  }, []);

  const [isModalOpen, setModalOpen] = useState(true);

  return (
    <div
      className="AppWrapper"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <WelcomeModal setModalOpen={setModalOpen} isOpen={isModalOpen} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <h1 style={{ textTransform: "uppercase" }}>{globalConstants.title}</h1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "center",
          }}
        >
          <div id="it-board-wrapper" style={{}}>
            <Board
              setDone={setDone}
              onCorrectGuess={onCorrectGuess}
              checkCurrentGuess={checkCurrentGuess}
              setCheckCurrentGuess={setCheckCurrentGuess}
              pastGuesses={pastGuesses}
              currentGuess={currentGuess}
            />
          </div>
          <div
            id="it-spacer"
            style={{
              ...(isMobile
                ? {
                    transition: "height 4s ease-in",
                    height: isMove ? 95 : 0,
                  }
                : {
                    transition: "width 4s ease-in",
                    width: isMove ? 180 : 0,
                  }),
            }}
          />
          <div
            id="it-ButtonAndLetter"
            style={{
              transition: isMobile
                ? "height 4s ease-in, top 4s ease-in"
                : "width 4s ease-in, left 4s ease-in",
              position: isMoveDone ? undefined : "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: isMoveDone ? 100 : -1,
              ...(isMobile
                ? {
                    height: isMove ? 170 : 0,
                    top: isMove ? 0 : "-50%",
                  }
                : {
                    width: isMove ? 286 : 0,
                    left: isMove ? 0 : "-50%",
                  }),
            }}
          >
            <div
              id="it-button"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onClick={() => setReveal(true)}
              className={css(styles.button)}
              style={{
                width: 100,
                height: 100,
                pointerEvents: "all",
                cursor: "pointer",
                opacity: isReveal ? 0 : 1,
                transition: "opacity .3s ease",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {globalConstants.letterLock(isHovered)}
              <span
                className="material-icons lock"
                style={{
                  transition: "all .3s ease",
                  fontSize: isHovered ? 21 * 1.2 : 21,
                }}
              >
                {isHovered ? "lock_open" : "lock"}
              </span>
            </div>
            {isReveal && (
              <div
                style={{
                  ...(isMobile
                    ? {
                        top: 280,
                        bottom: 40,
                      }
                    : {
                        bottom: 170,
                        top: 80,
                      }),
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  transition: "opacity .3s ease",
                  opacity: isReveal ? 1 : 0,
                }}
              >
                <div
                  id="it-LetterWrapper"
                  style={{
                    // TODO animate
                    backgroundColor: "white",
                    ...(content.isPrivate
                      ? {}
                      : {
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }),
                    width: isMobile ? 286 : 520,
                    ...(isMobile
                      ? {
                          height: "44vh",
                        }
                      : {
                          maxHeight: "80vh",
                        }),
                    animation: "fadeInFromNone 1s ease-out",

                    ...letterOverflowStyles,
                  }}
                >
                  <CharacterReveal
                    animateOpacity
                    canPlay={isRevealText}
                    characterOffsetDelay={95} // ms
                    characterWordSpacing="10px"
                    copy={[
                      // in order to get this right
                      // look at in browser and break it up from there
                      ...content.letter,
                    ]}
                    direction="bottom"
                    duration={2000} // ms
                    ease="cubic-bezier(0.5848375451263538,-0.003374999999999906,0.16606498194945848,1.012625)"
                    offset="120px"
                    perspective
                    perspectiveX={158}
                    perspectiveY={13}
                    multilineMasking={false}
                    multilineOffsetDelay={575} // ms
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          transition: "opacity 2s ease",
          opacity: isDone ? 0 : 1,
          display: "flex",
          justifyContent: "center",
          marginBottom: 12,
        }}
      >
        <Keyboard enter={enter} backspace={backspace} onChange={onChange} />
      </div>

      <div>
        {faceIterator.map((_unused, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <FloatingFace key={index} isDone={isDone} />;
        })}
      </div>
    </div>
  );
}

export default App;
