export const globalConstants = {
  title: <>Ready When You Are</>,
  letterLock: (isHovered: boolean) => (
    <span
      className="material-symbols-outlined"
      style={{
        transition: "all .3s ease",
        position: "absolute",
        fontSize: isHovered ? 100 * 1.2 : 100,
      }}
    >
      park
    </span>
  ),
  emoji: "🇺🇸",
  holidayExclamation: <>Merry Christmas! 🎄</>,
  modal: {
    row1: ["w", "e", "l", "c", "o", "m", "e"],
    row1Answer: ["X", "e", "X", "X", "X", "o", "e"],
    row2: ["M", "H"],
    row2Answer: ["X", "H"],
    credit: "Think about your root word!",
  },
  letter: {
    letter: [
      `Dear Miss Helen,`,
      ``,
      `This Christmas, I've got a ticking surprise for the incredible`,
      `Mama who makes every second count. It's time to remind you`,
      `that you're the heartbeat of our family, and we love you more`,
      `than words can express! So, let's go on a watch-shopping`,
      `adventure and find you a tick-tock that will make you smile`,
      `just like that other tick-tock.`,
      ``,
      `Can't wait to see you shine brighter than a new timepiece!`,
      ``,
      `We love you the most`,
    ],
    letterMobile: [
      `Dear Miss Helen,`,
      ``,
      `This Christmas, I've got a ticking`,
      `surprise for the incredible Mama`,
      `who makes every second count.`,
      `It's time to remind you that`,
      `you're the heartbeat of our family`,
      `and we love you more than`,
      `words can express! So, let's`,
      `go on a watch-shopping`,
      `adventure and find you a`,
      `tick-tock that will make you`,
      `smile just like that other`,
      `tick-tock.`,
      ``,
      `Can't wait to see you shine`,
      `brighter than a new timepiece!`,
      ``,
      `We love you the most`,
    ],
  },
  puzzleAnswer: [
    "make".split(""),
    "time".split(""),
    "to".split(""),
    "go".split(""),
    "watch".split(""),
    "shopping".split(""),
  ],
};

// change index.html file
// change favicon
