import { useMemo, useState } from "react";

export const Tile: React.FC<{
  guessAnswerWord?: string[];
  guessAnswerLetter?: string;
  letter?: string;
  onCorrect?: () => void;
  isGuess?: boolean;
  lettersLeft?: Record<string, number>;
}> = ({
  letter,
  guessAnswerLetter,
  guessAnswerWord,
  onCorrect,
  isGuess,
  lettersLeft,
}) => {
  const [animationCounter, setAnimationCounter] = useState(0);
  const pastGuessStyles = useMemo(() => {
    if (!guessAnswerLetter || !guessAnswerWord) return setAnimationCounter(0);
    if (guessAnswerLetter === letter)
      return { color: "white", background: "#6aaa64" };
    if (guessAnswerLetter !== letter) {
      if (lettersLeft && letter && lettersLeft[letter]) {
        return { color: "white", background: "#c9b458" };
      }
      return { color: "white", background: "#939598" };
    }
  }, [guessAnswerLetter, guessAnswerWord, letter, lettersLeft]);

  const [animationName, setAnimationName] = useState<string>("FlipIn");

  const animationProps: React.CSSProperties =
    pastGuessStyles && isGuess && animationCounter < 2 && animationName
      ? {
          animationName,
          animationDuration: ".3s",
          animationFillMode: "forwards",
          animationTimingFunction: "ease-in",
          animationIterationCount: 1,
        }
      : {
          // animationName: 'FlipIn',
        };

  const canUseColorTiles = animationCounter >= 1 || !isGuess;

  const getBorderStyle = () => {
    if (!isGuess && pastGuessStyles) {
      return "2px solid transparent";
    }
    if (isGuess && canUseColorTiles) return "2px solid transparent";
    return "2px solid lightgrey";
  };

  return (
    <div
      onAnimationEnd={(e) => {
        if (e.animationName === "FlipIn") {
          setAnimationCounter((count) => count + 1);
          setAnimationName("FlipOut");
        }
        if (e.animationName === "FlipOut") {
          setAnimationCounter((count) => count + 1);
          setAnimationName("FlipIn");
          onCorrect?.();
        }
      }}
      style={{
        // transition: 'background .5s ease',
        // borderRadius: 3,
        // flexGrow: 0,
        // flexShrink: 0,
        height: "10vw",
        width: "10vw",
        maxWidth: 50,
        maxHeight: 50,
        border: getBorderStyle(),
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
        fontWeight: 600,
        display: "flex",
        fontSize: 28,
        ...animationProps,
        ...(canUseColorTiles ? pastGuessStyles : {}),
      }}
    >
      {letter}
    </div>
  );
};
